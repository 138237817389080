<template>
  <b-container class="mt-2">
    <b-row>
      <b-col>
        <b-overlay :show="loading" rounded="sm" variant="transparent">
          <template #overlay>
            <div class="text-center">
              <b-spinner />
              <p class="mt-1">
                Registrando establecimiento
              </p>
            </div>
          </template>
          <form-wizard color="#7367F0" :title="null" :subtitle="null" layout="horizontal"
            :finish-button-text="loading ? 'Procesando...' : 'Confirmar'" next-button-text="Siguiente"
            back-button-text="Regresar" class="wizard-vertical mb-3" shape="square" step-size="sm"
            @on-complete="formSubmitted">

            <!-- Datos del admin -->
            <tab-content title="Nombre" :before-change="validationForm">
              <validation-observer ref="accountRules" tag="form">
                <b-row>
                  <b-col cols="12">
                    <h2>
                      Dueño del Establecimiento
                    </h2>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                      Nombre del Dueño
                    </h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-ammount">
                      <validation-provider #default="{ errors }" name="Nombre" rules="required">
                        <b-input-group class="">
                          <b-form-input id="v-ammount" v-model="user.name" :state="errors.length > 0 ? false : null"
                            placeholder="Nombre" size="lg" type="text" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                      Correo
                    </h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldEmail">
                      <validation-provider #default="{ errors }" name="El correo electrónico es necesario"
                        rules="required">
                        <b-input-group>
                          <b-form-input id="v-loginFieldEmail" v-model="user.email"
                            :state="errors.length > 0 ? false : null" placeholder="JoseLopez@mywalleat.com" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                      Teléfono celular
                    </h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldPhone">
                      <validation-provider #default="{ errors }" name="El celular es necesario para validar su cuenta"
                        rules="required">
                        <b-input-group>
                          <b-form-input id="v-loginFieldPhone" v-model="user.phone_number"
                            :state="errors.length > 0 ? false : null" placeholder="5555555555" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Datos del establecimiento -->
            <tab-content title="Correo y número celular" :before-change="validationCelForm">
              <validation-observer ref="accountCelRules" tag="form">
                <b-row>
                  <b-col cols="12">
                    <h2>
                      Datos del negocio
                    </h2>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5">
                        Nombre del negocio
                      </label>
                      <validation-provider #default="{ errors }" name="El nombre del negocio es necesario"
                        rules="required">
                        <b-input-group>
                          <b-form-input id="v-loginFieldEmail" v-model="user.establishment_attributes.name"
                            :state="errors.length > 0 ? false : null" placeholder="Nombre del negocio" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldPhone">
                      <label class="h5">
                        Tipo de Establecimiento
                        </label>
                      <validation-provider #default="{ errors }" name="El Tipo de Establecimiento es necesario para validar su cuenta"
                        rules="required">
                        <b-input-group>
                            <b-form-select
                              v-model="user.establishment_attributes.establishment_type"
                              :options="establishment_types"
                            >
                            </b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldPhone">
                      <label class="h5">
                        ¿Provees productos o servicios a otros negocios?
                        </label>
                      <validation-provider #default="{ errors }" name="Esta información es necesaria para crear su cuenta"
                        rules="required">
                        <b-input-group>
                            <b-form-select
                              v-model="user.establishment_attributes.is_supplier"
                              :options="is_supplier_options"
                            >
                            </b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldPhone">
                      <label class="h5">
                        ¿Compartiras tu crédito para ventas de recargas con todas tus tienditas?
                      </label>
                      <validation-provider #default="{ errors }" name="Es necesario rellenar el campo"
                        rules="required">
                        <b-input-group>
                            <b-form-select
                              v-model="user.establishment_attributes.is_balance_shared"
                              :options="[{value: true, text:'Si'},{value: false, text:'No'}]"
                            >
                            </b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5">
                        RFC (opcional)
                      </label>
                      <validation-provider #default="{ errors }" name="El ID Fiscal es necesario"
                        rules="optional">
                        <b-input-group>
                          <b-form-input id="v-loginFieldEmail" v-model="user.establishment_attributes.fiscal_id"
                            :state="errors.length > 0 ? false : null" placeholder="Opcional" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5">
                        CLABE Interbancaria (opcional)
                      </label>
                      <validation-provider #default="{ errors }" name="El ID Bancario es necesario"
                        rules="optional">
                        <b-input-group>
                          <b-form-input id="v-loginFieldEmail" v-model="user.establishment_attributes.bank_unique_id"
                            :state="errors.length > 0 ? false : null" placeholder="Opcional" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5">
                        ¿Cuál es el máximo que le fías a tus clientes?
                      </label>
                      <validation-provider #default="{ errors }" name="El Límite de prestamo es necesario"
                        rules="required">
                        <b-input-group>
                          <b-form-input id="v-loginFieldEmail" v-model="user.establishment_attributes.loan_balance_limit"
                            :state="errors.length > 0 ? false : null" placeholder="0" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Dirección -->
            <tab-content title="Dirección" :before-change="validationDirForm">
              <validation-observer ref="accountDirRules" tag="form">
                <b-row>
                  <b-col cols="12" class="my-2">
                    <small class="text-warning">
                      La siguiente dirección será utilizada para pedidos realizados en línea. Recuerda que
                      posteriormente puede ser modificada. Si no encuentras tu calle, escribe el nombre de tu ciudad y cuando el mapa este en tu ciudad, presiona en tu ubicación
                    </small>
                  </b-col>
                  <b-col cols="12">
                    <!-- TODO: Add the element to search the directionon the google maps -->
                    <!-- <address-form-model info-type @getAddress="setAddressForEdit($event)" /> -->
                    <gmap-autocomplete
                        ref="googleAddress"
                        class="mb-2"
                        :componentRestrictions="{country: 'mx'}"
                        @place_changed="setAddressForEdit($event)"
                      />
                    <GmapMap @click="setMarker($event)" :center="center" :zoom="zoom" map-type-id="roadmap"
                      style="width: 500px; height: 300px">
                      <GmapMarker :position="marker" :clickable="true"
                        :draggable="false" @update="changeMarker($event)"/>
                    </GmapMap>

                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Resumen de información -->
            <tab-content title="Confirmar">
              <b-row>
                <b-col>
                  <div class="mb-1">
                    <p class="mb-0 text-muted">
                      Nombre:
                    </p>
                    <p class="display-5">
                      {{ user.name }}
                    </p>
                    <p class="mb-0 text-muted">
                      Correo:
                    </p>
                    <p class="display-5">
                      {{ user.email }}
                    </p>
                    <p class="mb-0 text-muted">
                      Celular:
                    </p>
                    <p class="display-5">
                      {{ user.phone_number }}
                    </p>
                  </div>
                  <b-row>
                    <b-col>
                      <p class="d-flex flex-column">
                        <span class="text-muted">
                          Calle y número :
                        </span>
                        {{ user.establishment_attributes.address_attributes.street }}, #{{
                          user.establishment_attributes.address_attributes.ext_number }}
                      </p>
                    </b-col>
                    <b-col>
                      <p class="d-flex flex-column">
                        <span class="text-muted">
                          Código Postal:
                        </span>
                        {{ user.establishment_attributes.address_attributes.postal_code }}
                      </p>
                    </b-col>
                    <b-col>
                      <p class="d-flex flex-column">
                        <span class="text-muted">
                          País y cuidad:
                        </span>
                        {{ user.establishment_attributes.address_attributes.country }}, {{
                          user.establishment_attributes.address_attributes.state }},{{
                          user.establishment_attributes.address_attributes.city }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </tab-content>

          </form-wizard>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import '@/@core/scss/vue/libs/vue-wizard.scss'
import AddressFormModel from '@/@core/components/CustomerAddressFormModel.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getGoogleMapsAPI } from 'gmap-vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddressFormModel,
  },
  data() {
    return {
      user: {
        name: null,
        email: null,
        phone_number: null,
        establishment_attributes: {
          name: "",
          cel_number: null,
          address_attributes: {},
          contact_name: "",
          contact_email: "",
          is_balance_shared: "",
          fiscal_id: "",
          bank_unique_id: "",
          cel_number: "",
          establishment_type: "",
          loan_balance_limit: "",
        },
      },
      required,
      email,
      loading: false,
      marker: {},
      center: { lat: 25.6866142, lng: -100.3161126 },
      zoom: 10,
      establishment_types: [
        { value: 'groceries', text: 'Tiendita' },
        { value: 'school', text: 'Escuela' },
        { value: 'restaurant', text: 'Restaurante' },
        { value: 'flower_store', text: 'Librería' },
        { value: 'hardware_store', text: 'Ferretería' },
        { value: 'tortilleria', text: 'Tortillería' },
        { value: 'coffee_shop', text: 'Cafetería' },
        { value: 'clothing_store', text: 'Tienda de ropa' },
        { value: 'water_purifier', text: 'Purificadora de agua' },
        { value: 'corporate_education', text: 'Educación corporativa' },
        { value: 'other', text: 'Otra' },
      ],
      is_supplier_options: [
        { value: 'supplier', text: 'Si' },
        { value: 'not_supplier', text: 'No' },
      ],
    }
  },
  mounted(){
  },
  methods: {
    ...mapActions('users', ['registerAsEstablishment']),
    getAddress(place) {
      const address = {
        name: '',
        state: '',
        country: '',
        city: '',
        street: '',
        ext_number: '',
        int_number: '',
        postal_code: '',
        lat: '',
        lng: '',
        suburb: '',
        url: '',
        formatted_address: '',
      }
      place.address_components.forEach(x => {
        if (x.types.includes('administrative_area_level_1')) {
          address.state = x.long_name
        }
        if (x.types.includes('country')) {
          address.country = x.long_name
        }
        if (x.types.includes('city') || x.types.includes('locality')) {
          address.city = x.long_name
        }
        if (x.types.includes('street') || x.types.includes('route')) {
          address.street = x.long_name
        }
        if (
          x.types.includes('ext_number')
          || x.types.includes('street_number')
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes('postal_code')) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes('suburb')
          || x.types.includes('sublocality_level_1')
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    setMarker(event) {
      // goecode manual marker to get address address
      // eslint-disable-next-line
      const mapsapi = new getGoogleMapsAPI()
      // console.log(mapsapi.maps.Geocoder.prototype.geocode);
      // const geocoder = new google.gmapApi().maps.Geocoder
      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      mapsapi.maps.Geocoder.prototype.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[1]) {
            this.$refs.googleAddress.$el.children[0].value = results[1].formatted_address
            this.user.establishment_attributes.address_attributes = this.getAddress(results[1])
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Introduzca la direccion manualmente',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      this.user.establishment_attributes.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      this.marker = {
        lat,
        lng,
      }
      console.log(this.user)
    },
    setAddressForEdit(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.zoom = 16
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.user.establishment_attributes.address_attributes = this.getAddress(place)
    },
    formSubmitted() {
      this.loading = true
      this.user.establishment_attributes.contact_name = this.user.name
      this.user.establishment_attributes.contact_email = this.user.email
      this.user.establishment_attributes.cel_number = `${52}${this.user.phone_number}`
      const establishment = {
        ...this.user,
        phone_number: `${52}${this.user.phone_number}`,
      }
      this.registerAsEstablishment(establishment)
        .then(() => {
          this.$swal({
            title: 'establecimiento registrado exitosamente!',
            text: 'Revisa tus mensajes de texto para terminar tu registro.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$router.push({ name: 'home' })
        })
        .catch(error => {
          if (error.response.data.messages) {
            this.$swal({
              title: 'Error!',
              text: error.response.data.messages[0],
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
        .then(() => {
          this.loading = false
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationCelForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountCelRules.validate().then(success => {
          if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email) && /[0-9]{10}/.test(this.user.phone_number)) {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: 'El correo ó celular registrado no es válido',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            reject()
          }
        })
      })
    },
    validationDirForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountDirRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
.wizard-icon-container {
  border-radius: 6px !important;
  background: red;
}

.wizard-nav.wizard-nav-pills {
  display: none;
}
.pac-target-input{
  color: #b4b7bd;
  background-color: #283046;
  border: 1px solid #404656;

	padding: 0.438rem 1rem;
	border-radius: 0.357rem;
  width: 100%;

}
</style>
